import { Link } from "react-router-dom";
export default function Footer() {
  const click = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer bg-neutral text-lg text-neutral-content p-10 pb-44">
      <nav>
        <h6 className="text-gray-400 font-bold text-lg">CONTACT</h6>
        <a  className="cursor-default">
          Business Name:   
          <Link to="/" onClick={click}>
             &nbsp;<u><span className="text-[#ff8401] cursor-pointer">Prime</span>Kit</u>
          </Link>
        </a>
        <a
          className="cursor-default"
          href="https://wa.me/918888999827?text=hellothere"
        >
          Contact:
          <span className="cursor-pointer hover: hover:text-green-400">
           
            <u>+918888999827</u>
          </span>
        </a>
        <a className="cursor-default">Business Hours: Mon-Sat(11AM - 7PM)</a>
        {/* <a className="cursor-default" href="mailto:jankivaja26@gmail.com">Email: <span className='hover:text-blue-500'>primekit@gmail.com</span></a> */}
        <a className="cursor-default">All rights reserved by   
        <Link to="/" onClick={click}>
        &nbsp;<u>
              <span className="text-[#ff8401] cursor-pointer">Prime</span>Kit.
            </u>
          </Link>
            </a>
      </nav>
      <nav>
        <h6 className="text-gray-400 font-bold text-lg">POLICY MENU</h6>

        <Link to="/contactus">
          <a className="link link-hover">Contact</a>
        </Link>
        <Link to="/tnc">
          <a className="link link-hover">Term of Services</a>
        </Link>
      </nav>
    </footer>
  );
}
