import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="navbar bg-black  z-0">
      <div className="avatar gap-2  ml-3 mt-1 cursor-pointer">
        <div className="  h-[50px] w-[250px] rounded-lg   ">
          <Link to="/">
            <img alt="" src="logo_horizontal.webp" />
          </Link>
        </div>
      </div>
    </div>
  );
}
