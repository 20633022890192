import { IoIosStar } from "react-icons/io";
import { Link } from "react-router-dom";
export default function Home() {
  const openPayment = () => {
    console.log("open");
  };
  const advantage = [
    {
      title: "Comprehensive Resources",
      description:
        "Access over 1000 templates covering all essential startup needs.",
    },
    {
      title: "Time-Saving",
      description:
        "Save countless hours with ready-to-use templates for every aspect of your business.",
    },
    {
      title: "Professional Quality",
      description:
        "High-quality, expertly designed templates that enhance your business presentations and documents.",
    },
    {
      title: "Versatile Formats",
      description:
        "Includes templates in PDF, Excel, PPT, and DOCX formats, suitable for various uses.",
    },
    {
      title: "Boost Efficiency",
      description:
        "Streamline your operations and improve productivity with well-organized templates.",
    },
    {
      title: "Cost-Effective",
      description:
        "Affordable solution compared to creating custom templates from scratch.",
    },
    {
      title: "Easy Customization",
      description:
        "Templates are fully customizable to fit your specific requirements.",
    },
    {
      title: "Enhanced Collaboration",
      description:
        "Improve team collaboration with standardized templates that everyone can use.",
    },
    {
      title: "Accelerate Growth",
      description:
        "Focus on scaling your startup by reducing the time spent on administrative tasks.",
    },
    {
      title: "User-Friendly",
      description:
        "Simple and intuitive templates that anyone on your team can easily use.",
    },
  ];
  const reviews = [
    {
      name: "Twisha shah",
      image: "girl1.webp",
      day: "1 days ago",
      review:
        "The Startup Turbo Pack simplifies my daily tasks,providing essential template for legal agrements and operation.It saves time,ensures legality, and empowers efficient startup management.Highly recommended for entrepreneurs.",
    },
    {
      name: "Suresh Jain",
      image: "boy3.webp",
      day: "2 days ago",
      review: "This is amazing! Thank you @PrimeKit! You have my vote! ",
    },
    {
      name: "Amit Agrawal",
      image: "boy1.webp",
      day: "10 days ago",
      review:
        "Without a doubt, Startup Turbo Pack Document is worth every penny of the investment.Its comprehensive collection of templates saves invaluable time and effort,ensuring legal compliance and smooth operations.An indispensable asset for any entrepreneur, the benifits far outweigh the cost.",
    },
    {
      name: "Chirag Sinha",
      image: "boy2.webp",
      day: "14 days ago",
      review:
        "Startup Turbo Pack is a lifesaver for entrepreneurs.With its comprehensive range of templates,it simplifies legal processes and operational tasks, saving time and ensuring legality.Highly recommended for streamlined startup management.",
    },
    {
      name: "Jenny Dave",
      image: "girl2.webp",
      day: "20 days ago",
      review:
        "Startup Turbo Pack stands out in the cafe franchise indusrty due to its tailored approach.It offers a specialized set of templates addressing unique franchise needs,streamlining legal compliance and operational setup.A must have for cafe enterpreneurs seeking efficient franchise management.",
    },
  ];
  return (
    <div className="w-full h-full  flex flex-col   ">
      <div
        className="lg:w-[50%] text-center"
        style={{
          alignSelf: "center",
        }}
      >
        <p
          className="font-weight: 900; lg:font-semibold  
            mt-14 text-5xl lg:text-7xl sm:text-6xl md:text-6xl"
        >
          We are helping
          <span className="text-blue-600 font-bold"> startups</span> to organise
          their
          <span className="text-[#FE8300] font-bold"> business</span>
        </p>
      </div>

      <div className="pb-20">
        <div className="avatar-group -space-x-6 rtl:space-x-reverse  justify-center mt-9">
          <div className="avatar">
            <div className="w-14">
              <img alt="" src="profileimg1.webp" className="object-cover" />
            </div>
          </div>
          <div className="avatar">
            <div className="w-14">
              <img alt="" src="profileimg2.webp" className="object-cover" />
            </div>
          </div>
          <div className="avatar">
            <div className="w-14">
              <img alt="" src="profileimg3.webp" className="object-cover" />
            </div>
          </div>
        </div>
        <div className="icon-div flex flex-row justify-center mt-2  ">
          <IoIosStar size={30} className="text-yellow-500" />
          <IoIosStar size={30} className="text-yellow-500" />
          <IoIosStar size={30} className="text-yellow-500" />
          <IoIosStar size={30} className="text-yellow-500" />
          <IoIosStar size={30} />
        </div>
        <p className="text-2xl font-bold mt-2 text-center">
          Trusted by 1000+ Founders
        </p>

        <div className="card-container flex justify-center mt-9 ">
          <div className="card card-compact bg-base-100 w-80 lg:w-96  shadow-xl ">
            <figure>
              <img src="kit.webp" className="h-96  object-cover" alt="" />
            </figure>
            <div className="card-body">
              <h2 className="card-title ">Startup Turbo Pack</h2>
              <p className=" text-sm">
                3GB+ Editable Startup Turbo Bundle that boosts your Fundraising,
                Finance, Marketing, Sales,Hiring, and Legal Processses for your
                Company/Startup.
              </p>
              <div className="flex justify-end mt-3">
                <div>
                  <p className="ml-14">
                    <del>Rs. 1,999/-</del>
                  </p>
                  <p className="text-green-700 mt-1 ml-9 font-bold text-2xl">
                    Rs. 149/-
                  </p>
                  <Link to={"./form"}>
                    <button className="btn hover:bg-gray-700 w-36 bg-black mt-4 text-2xl text-white" style={{height:'30px'}}>
                      Get Now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 text-left lg:text-center p-3">
          <p className="font-weight-800 text-3xl ">
            Welcome to Your
            <span className="text-blue-600"> One-Stop Solution</span> for
            Seamless Documentation and Legal Success!
          </p>
          <p className="mt-3 lg:mx-60">
            With our meticulously crafted 5000+ Ultimate Legal Drafts Bundle,
            you're not just getting a bundle of documents; you're getting a
            comprehensive toolkit to revolutionize your startup journey.
          </p>
        </div>

        <div className=" mt-9  mx-3 lg:mx-40">
          {advantage.map((item) => (
            <div className="card bg-base-100 shadow-xl mt-5">
              <div className="p-3 flex flex-row items-center gap-2">
                <img alt="" src="right.png" style={{ height: "25px" }} />
                {/* <FaCheckCircle className="text-green-500 text-5xl lg:text-2xl" /> */}
                <p className="text-lg">
                  <b>{item.title}:</b> {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className=" text-left lg:text-left mt-5 p-3">
          <p className="lg:mx-40">
            But that's not all! Our bundle also includes essential modules to
            drive your startup's success:
          </p>

          <p className="lg:mx-40 mt-4">
            <b className="text-lg text-[#FE8300]">Marketing Modules:</b> Access
            expertly crafted marketing documents to launch your campaigns with
            confidence and attract your target audience effectively.
          </p>
          <p className="lg:mx-40 mt-3">
            <b className="text-lg text-[#FE8300]">Sales Modules:</b> Equip your
            sales team with the tools they need to close deals efficiently and
            drive revenue growth.
          </p>
          <p className="lg:mx-40 mt-3">
            <b className="text-lg text-[#FE8300]">Fundraising Modules:</b>{" "}
            Navigate the fundraising process seamlessly with comprehensive
            documents that impress investors and secure the funding your startup
            needs to thrive.
          </p>
        </div>

        <div className="mt-7 text-center">
          <h1 className="text-2xl lg:text-3xl  text-center font-semibold">
            Why choose our Turbo Pack
            <span className="text-[#FE8300]"> Bundle?</span>
          </h1>
          <img
            alt=""
            className="w-[90%] m-auto md:w-[60%] lg:w-[40%]  mt-7"
            src="https://bundlecrafters.com/wp-content/uploads/2022/12/Your-paragraph-ext.png"
          />
        </div>

        <div className=" text-left lg:text-left mt-6 p-3">
          <p className="lg:mx-40 ">
            <b className="text-lg text-[#FE8300]">Comprehensive Collection:</b>{" "}
            Dive into a vast array of meticulously crafted legal dreafts
            covering various legal areas, marketing strategies, sales
            tactics,fundraising documents, and more.
          </p>
          <p className="lg:mx-40 mt-4">
            <b className="text-lg text-[#FE8300]">Editable Templates:</b> Each
            draft comes with customizable sections, allowing you to tailor them
            to your specific needs without starting from scratch.
          </p>
          <p className="lg:mx-40 mt-4">
            <b className="text-lg text-[#FE8300]">Trusted and Reliable:</b> Our
            drafts are professionally designed, reviewed, and vetted by legal
            experts and industry Professionals, ensuring accuracy, reliablity,
            and effectiveness
          </p>
          <p className="lg:mx-40 mt-4">
            <b className="text-lg text-[#FE8300]">User-Friendly Format:</b>{" "}
            Whether you're a seasoned enterpreneur or just starting your startup
            journey,our documents are presented in a user -friendly format for
            easy navigation and utilization.
          </p>
          <p className="lg:mx-40 mt-4">
            By investing in our Ultimate Startup Turbo Drafts Bundle, you're not
            just inversting in documents;you're investing in the sucesss and
            growth of your startup. Imagine the time and effort saved, allowing
            you to focus more on innovation, growth, and achieving your
            startup's vision. Don't miss out on this incredibble opportunity to
            elevate your startup journey and streamline your documentation
            processes. Join thousands of satisfied customers who have already
            transformed their startups with our Ultimate Startup Turbo Drafts
            Bundle
          </p>

          <p className="text-center mt-6">
            Invest in your startup's success today!
          </p>

          <p className="text-center text-2xl mt-4">
            <del>Rs. 1,999/-</del>
          </p>
          <p className="text-center text-green-600 font-bold text-4xl mt-6">
            Rs. 149/-
          </p>
        </div>

        <div className="btn-div text-center mt-6">
          <Link to="/form">
            <button className="btn hover:bg-gray-700 bg-black text-white text-center text-lg font-normal ">
              Get Your Startup Turbo Pack Now
            </button>
          </Link>
        </div>

        <div className="review-div  text-center mt-9">
          <p className="font-semibold text-3xl">
            What <span className="text-blue-500">people</span> are saying about
            this <span className="text-[#FE8300]">startup Turbo Pack?</span>
          </p>
        </div>

        {reviews.map((person) => (
          <div className="card bg-blue-700 text-white mx-3 lg:mx-96  shadow-xl p-3 mt-10 ">
            <div className="flex items-center gap-4">
              <img
                className="w-10 h-10 object-cover rounded-full"
                src={person.image}
                alt=""
              />
              <div>
                <div className="font-semi-bold" style={{ fontSize: "16px" }}>
                  {person.name}
                </div>
                <div style={{ fontSize: "12px" }}>{person.day}</div>
              </div>
            </div>
            <div className="icon-div flex   mt-2  ">
              <IoIosStar size={13} className="text-yellow-500" />
              <IoIosStar size={13} className="text-yellow-500" />
              <IoIosStar size={13} className="text-yellow-500" />
              <IoIosStar size={13} className="text-yellow-500" />
              <IoIosStar size={13} className="text-yellow-500" />
            </div>
            <p style={{ fontSize: "12px", marginTop: "13px" }}>
              {person.review}
            </p>
          </div>
        ))}
        <div className="btn-div text-center mt-20">
          <Link to="/form">
            <button className="btn hover:bg-gray-700 bg-black text-white text-center text-2xl font-normal ">
              Get Pack Now
            </button>
          </Link>
        </div>
      </div>

      <div className="footer bg-black  text-white flex flex-row  p-3 pt-6 position: fixed left-0 right-0 bottom-0 w-full">
        <div className="justify-items-start flex flex-row w-full">
          <img
            alt=""
            className="w-14"
            src="https://png.pngtree.com/template/20220215/ourmid/pngtree-drug-packaging-prototype-image_752696.jpg"
          />
          <div className="mt-1 mxl-2">
            <p className="font-bold">Startup Turbo Pack</p>

            <span className="font-normal" style={{ fontSize: "11px" }}>
              scale your business now
            </span>
            <br />
            <span className="font-normal " style={{ fontSize: "11px" }}>
              <i>*Offer Valid Till Tonight</i>
            </span>
          </div>
        </div>

        <div className="justify-items-end  w-44">
          <p className="font-bold " style={{ fontSize: "24px" }}>
            Rs. 149/-
            <br />
            <span className="text-sm font-normal ml-5">
              <del>Rs. 1,999/-</del>
            </span>
          </p>

          <Link to="/form">
            <button className="btn btn-primary w-40 border-white hover:border-orange-500 hover:bg-white text-2xl mt-2  text-black bg-[#ff8401] ">
              Get Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
