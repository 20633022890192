import React, { useEffect } from 'react';

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="flex items-center justify-center mt-7 mb-7 lg:mt-24 lg:mb-24">
                <div className="w-full lg:w-1/2 border p-7 rounded-xl shadow-xl m-4">
                    <p className='text-4xl text-left text-black font-bold border-b-2 pb-2'>Contact Us</p>
                    <p className='text-xl font-bold text-left text-black mt-5'>Last updated on 28-06-2024 18:39:48</p>
                    <p className='text-xl text-left text-black mt-5'>You may contact us using the information below:</p>
                    <ul className='list-disc pl-5'>
                        <li>
                            <p className='text-sm text-left text-black mt-5'>Merchant Legal entity name: GAUTAMBHAI RAGHAVBHAI MER.</p>
                        </li>
                    </ul>
                    <ul className='list-disc pl-5'>
                        <li>
                            <p className='text-sm text-left text-black mt-5'>Registered Address: F-05, Aavkar apartment, Kaliyabid., Bhavnagar, Gujarat, PIN: 364001</p>
                        </li>
                    </ul>
                    <ul className='list-disc pl-5'>
                        <li>
                            <p className='text-sm text-left text-black mt-5'>Operational Address: F-05, Aavkar apartment, Kaliyabid., Bhavnagar, Gujarat, PIN: 364001</p>
                        </li>
                    </ul>
                    <ul className='list-disc pl-5'>
                        <li>
                            <p className='text-sm text-left text-black mt-5'>Telephone No: 8000620288</p>
                        </li>
                    </ul>
                    <ul className='list-disc pl-5'>
                        <li>
                            <p className='text-sm text-left text-black mt-5'>E-Mail ID: mergautam9@gmail.com</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>

    );
};

export default ContactUs;