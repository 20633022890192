import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Form from "./pages/Form";
import ContactUs from "./pages/Contactus";
import Tnc from "./pages/Tnc";
import Success from "./pages/Success";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/form" element={<Form />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/tnc" element={<Tnc />} />
        <Route path="/success" element={<Success />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
