import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { cashfree } from './util';
import { Link, useNavigate } from 'react-router-dom';

function Form() {
    const [sessionId, setSessionId] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [price, setPrice] = useState("149.00");

    let navigate = useNavigate();
    

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const checkoutOrder = (e) => {
        e.preventDefault();
        console.log(fname, lname, email, phone);
        const uniqueId = () => Math.floor(100000 + Math.random() * 900000).toString();
        console.log(uniqueId(), "uniqueId");
        const param = {
            "order_amount": price,
            "order_currency": "INR",
            "customer_details": {
                "customer_id": uniqueId(),
                "customer_name": fname + lname,
                "customer_email": email,
                "customer_phone": "+91" + phone
            },
            "order_meta": {
                "return_url": "https://b8af79f41056.eu.ngrok.io?order_id=order_123"
            }
        }

        const myHeaders = new Headers();
        myHeaders.append("X-Client-Secret", "cfsk_ma_test_7d07a96a4e07244f5ec661b9abb40d2f_89e5db3a");
        myHeaders.append("X-Client-Id", "TEST1023037050262c88bb30c25a61a207303201");
        myHeaders.append("x-api-version", "2023-08-01");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const options = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(param)
        };
        console.log(JSON.parse(options.body), "options");
        fetch('https://cors-anywhere.herokuapp.com/https://sandbox.cashfree.com/pg/orders', options).then(response => response.json())
            .then(data => {
                handleRedirect(data.payment_session_id);
            })
            .catch(error => console.error(error, "error"));
    }

    const handleRedirect = (id) => {
        console.log(sessionId, id, "sessionIdsessionIdsessionId");
        let checkoutOptions = {
            paymentSessionId: id,
            redirectTarget: "_modal",
        }
        cashfree.checkout(checkoutOptions).then(function (result: any) {
            console.log(result, "result");
            if (result.error) {
                console.log(result.error, "error");
            }
            if (result.redirect) {
                console.log(result.redirect, "redirection");
            }
            if (result.paymentDetails) {
                console.log(result.paymentDetails, "paymentDetails");
                navigate('/success');
                // Extract necessary details for redirection;
                // const paymentStatus = result.paymentDetails.status;
                // const paymentId = result.paymentDetails.paymentId;

                // Redirect to payment status page
                // window.location.href = `payment-status.html?status=${paymentStatus}&paymentId=${paymentId}`;
            }
        });
    }

    return (
        <div className='card-container flex items-center justify-center min-h-screen'>
            <div className="card card-compact bg-base-100 border shadow-xl w-full m-7 rounded-2xl lg:w-1/2">
                <div className='flex items-center justify-start p-7 border-b-2'>
                    <p className='text-3xl card-title'>Order details</p>
                </div>
                <div className='flex flex-col lg:flex-row p-4'>
                    <div className='flex flex-col items-center lg:items-start flex-1'>
                        <img src="kit.webp" alt="" className='h-auto w-auto lg:w-full lg:h-96' />
                        <p className='mt-3 text-xl text-green-700 font-bold'>Rs. {price}</p>
                    </div>
                    <div className='mt-7 lg:mt-0 lg:ml-7 flex flex-col items-center lg:items-start w-full lg:flex-1'>
                        <form className='w-full'>
                            <label htmlFor="firstName" className='text-black font-bold'>First Name</label>
                            <input
                                id="firstName"
                                type="text"
                                placeholder="Enter First Name"
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                                className="input input-bordered input-md w-full mt-2 mb-4" />

                            <label htmlFor="lastName" className='text-black font-bold'>Last Name</label>
                            <input
                                id="lastName"
                                type="text"
                                placeholder="Enter Last Name"
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                                className="input input-bordered input-md w-full mt-2 mb-4" />

                            <label htmlFor="email" className='text-black font-bold'>Email</label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="input input-bordered input-md w-full mt-2 mb-4" />

                            <label htmlFor="phone" className='text-black font-bold'>Phone Number</label>
                            <input
                                id="phone"
                                type="text"
                                placeholder="Enter Phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="input input-bordered input-md w-full mt-2 mb-4" />

                            <button type="button" onClick={checkoutOrder} className="btn bg-[#FF8401] text-white text-xl w-full lg:w-full mx-auto lg:mx-0">Continue</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Form;
