const Success = () => {

    const download = () => {

    }

    return (
        <>
            <div className="card-container flex flex-col items-center justify-center min-h-screen">
                
                <img src="sucess.png" alt="" className="h-150 w-150" />
                <p className="text-3xl font-bold pt-20">Payment Successfull</p>

                <button type="button" onClick={download} className="mt-5 btn bg-[#FF8401] text-white text-xl w-full lg:w-1/4 mx-auto lg:mx-0">Download</button>
            </div>
        </>
    )
}

export default Success;